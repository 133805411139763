<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {ref} from "vue"

defineProps({
  cms: Object,
  section: Object,
})

const openedItems = ref([])

function toggleItem(item) {
  if (openedItems.value.includes(item.id)) {
    openedItems.value = openedItems.value.filter((i) => i !== item.id)
  } else {
    openedItems.value = [...openedItems.value, item.id]
  }
}
</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <div class="">
      <h3 class="text-4xl font-semibold text-brown-700">{{ section.fields.title }}</h3>
    </div>

    <!-- items -->
    <div class="mt-8 flex flex-col gap-3">
      <div class="py-2 md:py-2" v-for="item in section.fields.questions" :key="item.id">
        <h4
          class="cursor-pointer text-xl font-semibold text-brown-800 hover:text-brown-700"
          @click="toggleItem(item)"
        >
          {{ item.fields.title }}
        </h4>
        <p
          class="mt-0 max-w-lg whitespace-pre-wrap text-lg text-brown-500"
          v-show="openedItems.includes(item.id)"
          v-html="item.fields.answer"
        />
      </div>
    </div>
  </LayoutContainer>
</template>